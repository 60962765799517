/* ./styles/globals.css */
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@600&display=swap');
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
*,
*:before,
*:after {
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}